<template>
  <v-container grid-list-md>
    <v-layout wrap>
      <v-flex>
        <v-card elevation="4" width="1000" class="mx-auto">
          <v-card-title primary-title>
            <v-toolbar flat>
              <v-layout row wrap>
                <v-flex xs8 md8>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="بحث"
                    single-line
                    hide-details
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs4 md4>
                  <filter-component
                    @filterDate="setFilteredDate"
                    :path="'Filter/AllVisit?user=' + user.id"
                  />
                </v-flex>
              </v-layout>
            </v-toolbar>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :search="search"
              :loading="loading"
              :headers="headers"
              :items="visits"
              sort-by="name"
              class="elevation-1 mt-3"
            >
              <template v-slot:top>
                <v-dialog v-model="dialogDelete" max-width="300px">
                  <v-card>
                    <v-card-title class="headline"
                      >هل أنت متأكد من الحذف؟</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text-color="white"
                        text
                        @click="closeDelete"
                        >إلغاء</v-btn
                      >
                      <v-btn
                        color="gray"
                        text-color="white"
                        text
                        @click="deleteItemConfirm"
                        >موافق</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>

              <!-- <template v-slot:[`item.name`]="{ item }"> 
                            {{item.visits.name}}
                        </template>  -->
              <!-- <v-flex class="mb-3" xs6 sm6 md6>
                                <b>المستخدم الموكلة إليه</b> 
                                   <b>{{Username}}</b> 
                            </v-flex> -->
              <template v-slot:[`item.status`]="{ item }">
                {{
                  item.task.statusId == 3
                    ? "منجزة"
                    : item.task.statusId == 4
                    ? "جديدة"
                    : item.task.statusId == 5
                    ? "قيد الانتظار"
                    : item.task.statusId == 2
                    ? "قيد التقدم"
                    : ""
                }}
              </template>

              <!-- <template v-slot:[`item.userResponsible`]="{item}">
             
              {{  userResponsible }}
                
              </template> -->

              <template v-slot:[`item.startDate`]="{ item }">
                {{ createdDate(item.task.startDate) }}
              </template>
              <template v-slot:[`item.endDate`]="{ item }">
                {{ createdDate(item.task.endDate) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  color="green"
                  text-color="white"
                  small
                  class="mr-2"
                  @click="
                    $router.push({
                      name: 'show-visit',
                      params: { visitId: item.idvisit, visit: item }
                    })
                  "
                >
                  mdi-eye
                </v-icon>
                <v-icon
                  color="blue"
                  v-if="role == 'Admin'"
                  text-color="white"
                  small
                  class="mr-2"
                  @click="
                    $router.push({
                      name: 'edit-task',
                      params: { taskId: item.task.idtask }
                    })
                  "
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  color="red"
                  v-if="role == 'Admin'"
                  text-color="white"
                  small
                  @click="deleteItem(item.idvisit)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:no-data> لا توجد بيانات </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import filterCom from "../shared/filterData.vue";
export default {
  components: {
    "filter-component": filterCom
  },

  data() {
    return {
      search: "",
      loading: false,
      dialogDelete: false,
      dialog: false,
      chatting: false,

      // task:{
      // responsibleId:''
      // },
      task: {
        statusId: ""
      },

      users: [],
      headers: [
        {
          text: " اسم الزيارة ",
          align: "center",
          sortable: true,
          value: "task.name"
        },

        {
          text: "حالة الزيارة",
          align: "center",
          sortable: true,
          value: "status"
        },

        {
          text: "نوع الجهة الخارجية",
          align: "center",
          sortable: true,
          value: "type"
        },

        {
          text: "تاريخ البداية",
          align: "center",
          sortable: true,
          value: "startDate"
        },
        {
          text: "تاريخ النهاية",
          align: "center",
          sortable: false,
          value: "endDate"
        },
        { text: "خيارات", align: "center", value: "actions", sortable: false }
      ],
      externalType: "",
      visits: [],
      showAcceptButton: false,
      deleteVisit: 0,
      page: 1,
      totalItems: 0,
      user: null,
      role: null,
      Username: "",
      userResponsible: ""
    };
  },

  methods: {
    getUsersName(id) {
      this.users = [];
      this.ApiService.get("auth/listusers")
        .then(res => {
          this.users = res.data;

          this.users.forEach(el => {
            if (el.user.id == id) {
              // this.userResponsible.push(el.user.fullName);
              this.userResponsible = el.user.fullName;
              // this.getuserres(this.userResponsible)
            }
          });
        })
        .catch(() => {});
    },
    async getName(id) {
      const res = await this.ApiService.get("Auth/GetUser?id=" + id);

      this.userResponsible = res.data.user.fullName;
      this.userResponsible.toString();
    },

    deleteItem(item) {
      this.deletedItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.ApiService.delete("Visit/DeleteVisit/" + this.deletedItem)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.getAllvisits();
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.deletedItem = null;
      this.dialogDelete = false;
    },
    setFilteredDate(data) {
      this.visits = [];
      this.visits = data;
    },
    getAllvisits() {
      this.loading = true;
      this.ApiService.get(
        "visit/GetAllVisitsByCreatorRole?user=" + this.user.id
      )
        .then(res => {
          
          this.visits = [];

          res.data.responseData.forEach(el => {
            if (el != null) {
              this.visits.push(el);
             
            }
          });
        })
        .catch(() => {});
      this.loading = false;
    },
    deleteTask() {
      this.dialog = false;
      this.ApiService.delete("task/DeleteTask/" + this.deleteVisit)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.getAllvisits(1);
          this.deleteVisit = 0;
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
    },
    detailsTask(task) {
      this.$router.push({ name: "visit-details", params: task });
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    },
    customFilter(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getAllvisits(1);
    // this.getUsersName()
    // this.getUsersName(this.visit.responsibleId);
  }
};
</script> 

<style lang="scss"></style>